<!-- 主营业务 -->
<template>
  <div class="container">
    <div class="header">
        <img
          class="hd-img"
          src="~@/assets/images/mainbusiness/main-hd.png"
          alt=""
          @click="() => {this.$router.push('/about')}"
        />
        <!-- <ul class="text">
          <li><span>三圆主营业务</span></li>
          <li>
            <p>
              了解我们的业务，<br />
              我们为您带来最完美的解决
            </p>
          </li>
          <li><a href="" class="more">了解更多</a></li>
        </ul>
        <img src="~@/assets/images/mainbusiness/main-banImg.png" alt="" /> -->
    </div>
    <div class="w pain">
      <span class="commTit" id="mainM01">运营痛点</span>
      <span class="commtit">Pain Points</span>
      <img style="width: 750rem;height: 529rem;" src="~@/assets/images/mainbusiness/main-point.png" alt="" />
    </div>
    <div class="main">
      <div class="w main-con">
        <div class="main-one">
          <ul class="data">
            <li>
              <div class="li-div">
                <span class="data-tit">1,000</span
                ><span class="data-con">累计GMV成交额</span>
              </div>
              <div>
                <span class="data-tit">7*16h</span
                ><span class="data-con">全年无休</span>
              </div>
            </li>
            <li>
              <div class="li-div">
                <span class="data-tit">30,000</span
                ><span class="data-con">品牌企业</span>
              </div>
              <div>
                <span class="data-tit">99.9%</span
                ><span class="data-con">用户度好评率</span>
              </div>
            </li>
            <li>
              <div>
                <span class="data-tit">30,00</span
                ><span class="data-con">客服团队</span>
              </div>
            </li>
          </ul>
          <div
            style="display: flex; flex-direction: column; align-items: center"
          >
            <img style="width: 634rem;height: 460rem;" src="~@/assets/images/mainbusiness/地图.png" alt="" />
            <p class="gap">三圆客服全国战区图</p>
          </div>
        </div>
        <div class="main-two">
          <div class="main-two-tit" id="mainM03">
            <span class="commTit">三圆六大优势</span>
            <span class="commtit">Advantages</span>
          </div>
          <ul class="box">
            <li>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <img style="width: 130rem;height: 130rem;" src="~@/assets/images/mainbusiness/运营.png" alt="" />
                <span
                  style="display: block; margin-bottom: 37rem; font-size: 30rem"
                  >标准化运营</span
                >
                <p style="font-size: 20rem; color: #666; text-align: center">
                  全国地区、经理、主管sop标准化线上办公
                </p>
              </div>
            </li>
            <li>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <img style="width: 130rem;height: 130rem;" src="~@/assets/images/mainbusiness/服务.png" alt="" />
                <span
                  style="display: block; margin-bottom: 37rem; font-size: 30rem"
                  >高品质服务</span
                >
                <p style="font-size: 20rem; color: #666; text-align: center">
                  分层运营、分层服务、7*16小时 在线，NPS用户口碑分析
                </p>
              </div>
            </li>
            <li>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <img style="width: 130rem;height: 130rem;" src="~@/assets/images/mainbusiness/质检.png" alt="" />
                <span
                  style="display: block; margin-bottom: 37rem; font-size: 30rem"
                  >高效率质检</span
                >
                <p style="font-size: 20rem; color: #666; text-align: center">
                  实时质检、人工质检、AI智能检 测，智能分析全面监控
                </p>
              </div>
            </li>
            <li>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <img style="width: 130rem;height: 130rem;" src="~@/assets/images/mainbusiness/实时数据.png" alt="" />
                <span
                  style="display: block; margin-bottom: 37rem; font-size: 30rem"
                  >实时数据</span
                >
                <p style="font-size: 20rem; color: #666; text-align: center">
                  自主研发三圆管理系统，全天实 时监控
                </p>
              </div>
            </li>
            <li>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <img style="width: 130rem;height: 130rem;" src="~@/assets/images/mainbusiness/行业认可.png" alt="" />
                <span
                  style="display: block; margin-bottom: 37rem; font-size: 30rem"
                  >行业认可</span
                >
                <p style="font-size: 20rem; color: #666; text-align: center">
                  阿里巴巴新进淘拍档金牌战略企业 ISO质量管理体系认证企业
                </p>
              </div>
            </li>
            <li>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <img style="width: 130rem;height: 130rem;" src="~@/assets/images/mainbusiness/高转化率.png" alt="" />
                <span
                  style="display: block; margin-bottom: 37rem; font-size: 30rem"
                  >高转化率</span
                >
                <p style="font-size: 20rem; color: #666; text-align: center">
                  专业人工客服外加三圆机器人智 能拥有精准的识别效果、处理复
                  杂业务问题的能力
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div class="main-three">
          <div class="main-two-tit" id="mainM04">
            <span class="commTit">服务数据案例</span>
            <span class="commtit">Data Cases</span>
          </div>
          <ul class="box">
            <li>
              <span
                style="display: block; font-size: 30rem; margin-bottom: 16rem"
                >58%</span
              >
              <span class="li-two">日化类平均转化率</span>
              <span class="li-line"></span>
              <p style="display: flex; justify-content: space-between">
                <span style="font-size: 12rem; color: #666666">
                  咨询人数：1125 </span
                ><span style="font-size: 12rem; color: #666666">
                  客服问答比：110%
                </span>
              </p>
              <p
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-top: 16rem;
                "
              >
                <span style="font-size: 12rem; color: #666666">
                  平均响应时间：25.06 </span
                ><span style="font-size: 12rem; color: #666666">
                  旺旺回复率：100%
                </span>
              </p>
              <p
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-top: 16rem;
                "
              >
                <span style="font-size: 12rem; color: #666666">
                  首次响应时间：21.73 </span
                ><span
                  style="
                    display: inline-block;
                    width: 104rem;
                    font-size: 12rem;
                    color: #666666;
                  "
                >
                  未回复人数：0
                </span>
              </p>
            </li>
            <li>
              <span
                style="display: block; font-size: 30rem; margin-bottom: 16rem"
                >147%</span
              >
              <span class="li-two">3C类问答比</span>
              <span class="li-line"></span>
              <p style="display: flex; justify-content: space-between">
                <span style="font-size: 12rem; color: #666666">
                  咨询人数：304 </span
                ><span style="font-size: 12rem; color: #666666">
                  客服问答比：140%
                </span>
              </p>
              <p
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-top: 16rem;
                "
              >
                <span style="font-size: 12rem; color: #666666">
                  平均响应时间：24.92 </span
                ><span style="font-size: 12rem; color: #666666">
                  旺旺回复率：100%
                </span>
              </p>
              <p
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-top: 16rem;
                "
              >
                <span style="font-size: 12rem; color: #666666">
                  首次响应时间：22.64 </span
                ><span
                  style="
                    display: inline-block;
                    width: 104rem;
                    font-size: 12rem;
                    color: #666666;
                  "
                >
                  未回复人数：0
                </span>
              </p>
              <p
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-top: 16rem;
                "
              >
                <span style="font-size: 12rem; color: #666666">
                  转化率：63%
                </span>
              </p>
            </li>
            <li>
              <span
                style="display: block; font-size: 30rem; margin-bottom: 16rem"
                >23.75</span
              >
              <span class="li-two">响应时间</span>
              <span class="li-line"></span>
              <p style="display: flex; justify-content: space-between">
                <span style="font-size: 12rem; color: #666666">
                  咨询人数：1912 </span
                ><span style="font-size: 12rem; color: #666666">
                  客服问答比：110%
                </span>
              </p>
              <p
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-top: 16rem;
                "
              >
                <span style="font-size: 12rem; color: #666666">
                  平均响应时间：25.96 </span
                ><span style="font-size: 12rem; color: #666666">
                  旺旺回复率：100%
                </span>
              </p>
              <p
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-top: 16rem;
                "
              >
                <span style="font-size: 12rem; color: #666666">
                  首次响应时间：23.75 </span
                ><span
                  style="
                    display: inline-block;
                    width: 104rem;
                    font-size: 12rem;
                    color: #666666;
                  "
                >
                  未回复人数：0
                </span>
              </p>
              <p
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-top: 16rem;
                "
              >
                <span style="font-size: 12rem; color: #666666">
                  转化率：59%
                </span>
              </p>
            </li>
            <li>
              <span
                style="display: block; font-size: 30rem; margin-bottom: 16rem"
                >57%</span
              >
              <span class="li-two">咨询人数</span>
              <span class="li-line"></span>
              <p style="display: flex; justify-content: space-between">
                <span style="font-size: 12rem; color: #666666">
                  咨询人数：945 </span
                ><span style="font-size: 12rem; color: #666666">
                  客服问答比：110%
                </span>
              </p>
              <p
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-top: 16rem;
                "
              >
                <span style="font-size: 12rem; color: #666666">
                  平均响应时间：26.34 </span
                ><span style="font-size: 12rem; color: #666666">
                  旺旺回复率：100%
                </span>
              </p>
              <p
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-top: 16rem;
                "
              >
                <span style="font-size: 12rem; color: #666666">
                  首次响应时间：24.63 </span
                ><span
                  style="
                    display: inline-block;
                    width: 104rem;
                    font-size: 12rem;
                    color: #666666;
                  "
                >
                  未回复人数：0
                </span>
              </p>
              <p
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-top: 16rem;
                "
              >
                <span style="font-size: 12rem; color: #666666">
                  转化率：57%
                </span>
              </p>
            </li>
          </ul>
        </div>
        <div class="main-four">
          <div class="main-two-tit" id="mainM05">
            <span class="commTit">服务类型</span>
            <span class="commtit">Service Type</span>
          </div>
          <div id="certify">
            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div
                    style="padding: 40rem;display: flex;flex-direction: column;justify-content: center;align-items: center;"
                  >
                    <span class="four-tit">售前服务</span>
                    <span class="four-money">1000&nbsp;元/起</span>
                    <div style="display: flex;flex-direction: column;">
                      <p>
                        <span class="four-line">
                          客服经验：
                        </span>
                        <span class="four-line-two">
                          1年以上
                        </span>
                      </p>
                      <p>
                        <span class="four-line">
                          擅长行业：
                        </span>
                        <span class="four-line-two">
                          服饰鞋包、美容护理、母婴用品、3C数码等20所多个类目
                        </span>
                      </p>
                      <p>
                        <span class="four-line">
                          主要职责：
                        </span>
                        <span class="four-line-two">
                          问候、答疑、导购、关联销售、催单、订单核实维护、重要信息报备等全方位服务
                        </span>
                      </p>
                    </div>
                    <span class="four-btn">免费试用</span>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div
                    style="padding: 40rem;display: flex;flex-direction: column;justify-content: center;align-items: center;"
                  >
                    <span class="four-tit">专席服务</span>
                    <span class="four-money">5000&nbsp;元/起</span>
                    <div style="display: flex;flex-direction: column;">
                      <p>
                        <span class="four-line">
                          服务介绍：
                        </span>
                        <span class="four-line-two">
                          适用于品质商家，一对一服务
                        </span>
                      </p>
                      <p>
                        <span class="four-line">
                          服务承诺：
                        </span>
                        <span class="four-line-two">
                          根据商家需求规划项目服务，承诺最大程度客户服务定制化
                        </span>
                      </p>
                    </div>
                    <span class="four-btn">免费试用</span>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div
                    style="padding: 40rem;display: flex;flex-direction: column;justify-content: center;align-items: center;"
                  >
                    <span class="four-tit">大促服务</span>
                    <span class="four-money">5000&nbsp;元/起</span>
                    <div style="display: flex;flex-direction: column;">
                      <p>
                        <span class="four-line">
                          服务介绍：
                        </span>
                        <span class="four-line-two">
                          双十一期间，适用于按天按小时合作商家
                        </span>
                      </p>
                      <p>
                        <span class="four-line">
                          服务承诺：
                        </span>
                        <span class="four-line-two">
                          在制定服务范围和时间内服务，承诺10分钟内即可对接上线接单
                        </span>
                      </p>
                    </div>
                    <span
                      @click="
                        () => {
                          goFree();
                        }
                      "
                      class="four-btn"
                      >免费试用</span
                    >
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div>
        <div class="main-five">
          <div class="main-two-tit" id="mainM06">
            <span class="commTit">服务平台</span>
            <span class="commtit">Platform</span>
          </div>
          <div>
            <ul
              style="display: flex;justify-content: space-between;padding-bottom: 71rem;"
            >
              <li>
                <img style="width: 145rem;height: 145rem;" src="~@/assets/images/mainbusiness/服务平台1.png" alt="" />
              </li>
              <li>
                <img style="width: 145rem;height: 145rem;" src="~@/assets/images/mainbusiness/服务平台2.png" alt="" />
              </li>
              <li>
                <img style="width: 145rem;height: 145rem;" src="~@/assets/images/mainbusiness/服务平台3.png" alt="" />
              </li>
              <li>
                <img style="width: 145rem;height: 145rem;" src="~@/assets/images/mainbusiness/服务平台4.png" alt="" />
              </li>
              <li>
                <img style="width: 145rem;height: 145rem;" src="~@/assets/images/mainbusiness/服务平台5.png" alt="" />
              </li>
              <li>
                <img style="width: 145rem;height: 145rem;" src="~@/assets/images/mainbusiness/服务平台6.png" alt="" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="w live">
      <div
        style="display: flex;flex-direction: column;justify-content: center;align-items: center;"
      >
        <span class="commTit">网红直播平台</span>
        <span class="commtit">Live Platform</span>
      </div>
      <div style="display: flex;justify-content: space-around;">
        <img style="width: 418rem;height: 602rem;" src="~@/assets/images/mainbusiness/手机.png" alt="" />
        <div>
          <span class="live-plat">三圆———直播网红的孵化平台</span><br />
          <span class="live-power">直播类百亿带货流量背后的客服支柱力量</span>
          <img style="width: 145rem;height: 145rem;" src="~@/assets/images/mainbusiness/平台1.png" alt="" />
          <img style="width: 145rem;height: 145rem;" src="~@/assets/images/mainbusiness/平台2.png" alt="" /><br />
          <img style="width: 109rem;height: 109rem;" src="~@/assets/images/mainbusiness/平台3.png" alt="" />
          <img style="width: 109rem;height: 109rem;" src="~@/assets/images/mainbusiness/平台4.png" alt="" />
          <img style="width: 109rem;height: 109rem;" src="~@/assets/images/mainbusiness/平台5.png" alt="" />
          <img style="width: 109rem;height: 109rem;" src="~@/assets/images/mainbusiness/平台6.png" alt="" />
        </div>
      </div>
    </div>
    <div class="process">
      <div class="w">
        <div class="pro-service">
          <div class="pro-one" id="mainM07">
            <span class="commTit">服务流程</span>
            <span class="commtit">Service Process</span>
          </div>
          <img style="width: 1171rem;height: 149rem;" src="~@/assets/images/mainbusiness/进度.png" alt="" />
        </div>
        <div class="promise">
          <div class="pro-one" id="mainM08">
            <span class="commTit">服务承诺</span>
            <span class="commtit">Our Promise</span>
          </div>
          <div style="display: flex;justify-content: center;">
            <img style="width: 960rem;height: 123rem;" src="~@/assets/images/mainbusiness/promise.png" alt="" />
          </div>
          <div class="pro-one">
            <span class="commTit">我们的承诺</span>
            <span class="free">三天免费客服体验，0成本获取报价方案</span>
          </div>
          <div>
            <div style="display: flex;justify-content: space-between;width:960rem;margin: 0 auto;">
              <input
                v-model="params.contactName"
                class="promise-ipt"
                type="text"
                placeholder="姓名"
              />
              <input
                v-model="params.phone"
                class="promise-ipt"
                type="text"
                placeholder="电话"
              />
              <input
                v-model="params.name"
                class="promise-ipt"
                type="text"
                placeholder="公司名称"
              />
            </div>
            <div
              style="display: flex;justify-content: space-evenly;margin: 30rem 0;"
            >
              <textarea v-model="params.note" class="promise-ipt ipt-w" />
            </div>
            <div class="oneLine">
              <input
                type="radio"
                style="font-size: 18rem;color: #ccc;"
                name="aa"
                v-model="params.intendedOrderTime"
                value="1"
              /><span class="radio-txt">免费使用</span>
              <input
                type="radio"
                style="font-size: 18rem;color: #ccc;"
                name="aa"
                v-model="params.intendedOrderTime"
                value="2"
              /><span class="radio-txt">一个月</span>
              <input
                type="radio"
                style="font-size: 18rem;color: #ccc;"
                name="aa"
                v-model="params.intendedOrderTime"
                value="3"
              /><span class="radio-txt">三个月</span>
            </div>
            <div
              class="form-btn"
              @click="
                () => {
                  commitFun();
                }
              "
            >
              提 交
            </div>
          </div>
        </div>
        <div class="brand">
          <div class="pro-one">
            <span class="commTit">服务品牌</span>
            <span class="commtit">Data Brand</span>
          </div>
          <div class="brand-img">
            <img style="width: 145rem;height: 145rem;" src="~@/assets/images/mainbusiness/服务品牌1.png" alt="" />
            <img style="width: 145rem;height: 145rem;" src="~@/assets/images/mainbusiness/服务品牌2.png" alt="" />
            <img style="width: 145rem;height: 145rem;" src="~@/assets/images/mainbusiness/服务品牌3.png" alt="" />
            <img style="width: 145rem;height: 145rem;" src="~@/assets/images/mainbusiness/服务品牌4.png" alt="" />
            <img style="width: 145rem;height: 145rem;" src="~@/assets/images/mainbusiness/服务品牌5.png" alt="" />
            <img style="width: 145rem;height: 145rem;" src="~@/assets/images/mainbusiness/服务品牌6.png" alt="" />
            <img style="width: 145rem;height: 145rem;"
              src="~@/assets/images/mainbusiness/服务品牌7.png"
              alt=""
            /><br />
            <img style="width: 145rem;height: 145rem;" src="~@/assets/images/mainbusiness/服务品牌8.png" alt="" />
            <img style="width: 145rem;height: 145rem;" src="~@/assets/images/mainbusiness/服务品牌9.png" alt="" />
            <img style="width: 145rem;height: 145rem;" src="~@/assets/images/mainbusiness/服务品牌10.png" alt="" />
            <img style="width: 145rem;height: 145rem;" src="~@/assets/images/mainbusiness/服务品牌11.png" alt="" />
            <img style="width: 145rem;height: 145rem;" src="~@/assets/images/mainbusiness/服务品牌12.png" alt="" />
            <img style="width: 145rem;height: 145rem;" src="~@/assets/images/mainbusiness/服务品牌13.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import Swiper from "swiper";
// import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
// SwiperCore.use([Navigation, Autoplay, Pagination]);
import { Config } from "../utils/index.js";

export default {
  name: "MainBusiness",
  components: {
    // Swiper,
    // SwiperSlide
  },
  props: {},
  data() {
    return {
      //分页器设置
      // swiperOptions: {
      //   watchSlidesProgress: true,
      //   slidesPerView: 3,
      //   centeredSlides: true,
      //   loop: true,
      //   loopedSlides: 3,
      //   autoplay: true,
      //   pagination: {
      //     el: ".swiper-pagination"
      //     //clickable :true,
      //   },
      //   on: {
      //     progress() {
      //       for (let i = 0; i < this.slides.length; i++) {
      //         var slide = this.slides.eq(i);
      //         var slideProgress = this.slides[i].progress;
      //         let modify = 1;
      //         if (Math.abs(slideProgress) > 1) {
      //           modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
      //         }
      //         let translate = slideProgress * modify * 145 + "rem";
      //         let scale = 1 - Math.abs(slideProgress) / 3;
      //         let zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
      //         slide.transform(
      //           "translateX(" + translate + ") scale(" + scale + ")"
      //         );
      //         slide.css("zIndex", zIndex);
      //         slide.css("opacity", 1);
      //         if (Math.abs(slideProgress) > 1) {
      //           slide.css("opacity", 0);
      //         }
      //       }
      //     },
      //     setTransition: function(transition) {
      //       for (var i = 0; i < this.slides.length; i++) {
      //         var slide = this.slides.eq(i);
      //         slide.transition(transition);
      //       }
      //     }
      //   }
      // },
      params: {
        name: "",
        intendedOrderTime: "1",
        note: "",
        contactName: "",
        phone: ""
      }
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    commitFun() {
      // 提交信息
      let params = { ...this.params };
      if (!params.name) {
        return alert("请填写公司名称！");
      }
      if (!params.intendedOrderTime) {
        return alert("请选择服务时间！");
      }
      if (!params.note) {
        return alert("请填写职位！");
      }
      if (!params.contactName) {
        return alert("请填写联系人！");
      }
      if (!params.phone) {
        return alert("请填写联系方式！");
      }
      this.$axios
        .post(Config.api.url + "/cms/cmsCustomer/insertTryCustomer", params)
        .then(function(res) {
          console.log(res.data.code);
          if (res.data.code == 200) {
            alert("提交成功，请耐心等待我们的联系！");
          } else {
            alert(res.data.message);
          }
        })
        .catch(function(err) {
          console.log(err);
        });
    },
    goFree() {
      this.$router.push("/business");
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    let swiper = new Swiper(".swiper-container", {
      watchSlidesProgress: true,
      slidesPerView: 3,
      centeredSlides: true,
      loop: true,
      loopedSlides: 3,
      autoplay: true,
      pagination: {
        el: ".swiper-pagination"
        //clickable :true,
      },
      on: {
        progress() {
          for (let i = 0; i < this.slides.length; i++) {
            var slide = this.slides.eq(i);
            var slideProgress = this.slides[i].progress;
            let modify = 1;
            if (Math.abs(slideProgress) > 1) {
              modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
            }
            let translate = slideProgress * modify * 145 + "rem";
            let scale = 1 - Math.abs(slideProgress) / 3;
            let zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
            slide.transform(
              "translateX(" + translate + ") scale(" + scale + ")"
            );
            slide.css("zIndex", zIndex);
            slide.css("opacity", 1);
            if (Math.abs(slideProgress) > 1) {
              slide.css("opacity", 0);
            }
          }
        },
        setTransition: function(transition) {
          for (var i = 0; i < this.slides.length; i++) {
            var slide = this.slides.eq(i);
            slide.transition(transition);
          }
        }
      }
    });
    swiper.init();
  }
};
</script>

<style lang="less" scoped>
.container {
  .header {
    background: #f8faff;
    .header-center {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 600rem;
    }
    .hd-img {
      width: 100%;
      cursor: pointer;
    }
    span {
      font-size: 60rem;
    }
    p {
      margin-top: 68rem;
      font-size: 34rem;
      color: #333333;
    }
    .more {
      display: inline-block;
      width: 230rem;
      height: 70rem;
      margin-top: 59rem;
      line-height: 70rem;
      text-align: center;
      font-size: 26rem;
      color: #fff;
      background: #4a61f1;
      box-shadow: 0rem 6rem 15rem rgba(20, 71, 199, 0.24);
      border-radius: 14rem;
    }
  }
  .pain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 826rem;
    padding-bottom: 59rem;
  }
  .main {
    background: #f8faff;
    .main-con {
      .main-one {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 60rem;
        .data {
          display: flex;
          flex-direction: column;
          margin-top: 35rem;
          li {
            display: flex;
            &:nth-child(-n + 2) {
              margin-bottom: 95rem;
            }
            div {
              display: flex;
              flex-direction: column;
              .data-tit {
                font-size: 38rem;
                color: #333333;
                margin-bottom: 28rem;
              }
              .data-con {
                font-size: 20rem;
                color: #666666;
              }
            }
            .li-div {
              margin-right: 120rem;
              width: 180rem;
            }
          }
        }
        .gap {
          margin-top: 49rem;
          font-size: 20rem;
          color: #7680e1;
        }
      }
      .main-two-tit {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .main-two {
        .box {
          li {
            display: inline-block;
            width: 374rem;
            max-height: 340rem;
            padding: 24rem 40rem 65rem;
            background: #ffffff;
            box-shadow: 0rem 6rem 15rem rgba(20, 71, 199, 0.24);
            border-radius: 30rem;
            &:nth-child(2),
            &:nth-child(5) {
              margin: 0 39rem;
            }
            &:nth-child(-n + 3) {
              margin-bottom: 40rem;
            }
          }
        }
      }
      .main-three {
        .box {
          display: flex;
          justify-content: space-between;
          li {
            display: inline-block;
            // width: 270rem;
            // height: 286rem;
            padding: 36rem 19rem;
            background: #ffffff;
            box-shadow: 0rem 6rem 15rem rgba(20, 71, 199, 0.24);
            border-radius: 30rem;
            &:nth-child(2) {
              margin: 0 40rem;
            }
            &:nth-child(3) {
              margin-right: 40rem;
            }
            .li-two {
              font-size: 15rem;
              color: #666666;
            }
            .li-line {
              display: block;
              margin: 10rem 0 29rem;
              width: 50rem;
              height: 4rem;
              background: #4a61f1;
              border-radius: 2rem;
            }
          }
        }
      }
      .main-four {
        // .swiper-img {
        //     height: 600rem;
        //     width: 100%;
        //     cursor: pointer;
        // }
        .four-tit {
          // margin: 40rem 0 15rem;
          margin-bottom: 15rem;
          font-size: 30rem;
        }
        .four-money {
          margin-bottom: 26rem;
          font-size: 24rem;
          color: #4a61f1;
        }
        .four-line {
          display: inline-block;
          font-size: 18rem;
          color: #333;
          margin-bottom: 8rem;
        }
        .four-line-two {
          font-size: 18rem;
          color: #666;
        }
        .four-btn {
          display: inline-block;
          margin-top: 20rem;
          width: 230rem;
          height: 60rem;
          font-size: 26rem;
          text-align: center;
          line-height: 60rem;
          color: #ffffff;
          background: #4a61f1;
          box-shadow: 0rem 6rem 15rem rgba(20, 71, 199, 0.24);
          border-radius: 14rem;
          cursor: pointer;
        }
      }
    }
  }
  .live {
    .live-plat {
      font-size: 36rem;
      color: #333333;
    }
    .live-power {
      display: block;
      margin: 49rem 0 106rem;
      font-size: 26rem;
      color: #666666;
    }
  }
  .process {
    background: #f8faff;
    .pro-one {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .promise {
      margin-top: 98rem;
      padding-bottom: 60rem;
      background: #fff;
      box-shadow: 0rem 6rem 15rem rgba(20, 71, 199, 0.24);
      border-radius: 30rem;
      .promise-text {
        text-align: left;
        font-size: 20rem;
        color: #333333;
      }
      li:nth-last-child(-n + 2) {
        margin-top: 34rem;
      }
      .free {
        margin: 28rem 0 30rem;
        font-size: 26rem;
        color: #333;
      }
      .promise-ipt {
        background: #fff;
        border: 1rem solid #4a61f1;
        border-radius: 10rem;
        width: 32%;
        box-sizing: border-box;
        height: 46rem;
        padding: 0 30rem;
        font-size: 18rem;
        color: #333;
        outline: none;
      }
      .ipt-w {
        width: 960rem;
        height: 200rem;
      }
      .oneLine {
        padding: 0 85rem;
        margin-top: 20rem;
        display: flex;
        align-items: center;
        input {
          cursor: pointer;
        }
        .radio-txt {
          font-size: 12rem;
          color: #666;
          margin-right: 32rem;
          vertical-align: middle;
        }
      }
      .form-btn {
        text-align: center;
        width: 529rem;
        height: 70rem;
        line-height: 70rem;
        margin: 60rem auto 0;
        color: #fff;
        border-radius: 14rem;
        background: #4a61f1;
        box-shadow: 0rem 6rem 15rem rgba(20, 71, 199, 0.24);
        font-size: 26rem;
        cursor: pointer;
      }
    }
    .brand {
      padding-bottom: 60rem;
      .brand-img {
        padding-top: 30rem;
        height: 342rem;
        text-align: center;
        background: #fff;
        box-shadow: 0rem 6rem 15rem rgba(20, 71, 199, 0.24);
        border-radius: 30rem;
      }
    }
  }

  #certify {
    position: relative;
    width: 1200rem;
    margin: 0 auto;
  }

  #certify .swiper-container {
    padding-bottom: 60rem;
  }

  #certify .swiper-slide {
    width: 580rem;
    height: 440rem;
    background: #fff;
    box-shadow: 0 8rem 30rem #ddd;
  }
  #certify .swiper-slide-active {
    width: 580rem !important;
    height: 440rem;
    background: #fff;
    border-radius: 30rem;
  }
  /deep/#certify .swiper-slide-prev {
    width: 320rem !important;
    height: 460rem !important;
    background: #eff1ff;
    border-radius: 30rem;
  }
  /deep/#certify .swiper-slide-next {
    width: 320rem !important;
    height: 460rem !important;
    background: #eff1ff;
    border-radius: 30rem;
  }
  // #certify .swiper-slide-duplicate-next{
  //   width: 0 !important;
  // }
  // #certify .swiper-slide img {
  //   display: block;
  // }
  // #certify .swiper-slide p {
  //   line-height: 98rem;
  //   padding-top: 0;
  //   text-align: center;
  //   color: #636363;
  //   font-size: 1.1em;
  //   margin: 0;
  // }

  #certify .swiper-pagination {
    width: 100%;
    height: 40rem;
    bottom: 0rem;
  }

  /deep/#certify .swiper-pagination-bullets .swiper-pagination-bullet {
    margin-right: 30rem !important;
    width: 20rem !important;
    height: 20rem !important;
    border-radius: 10rem !important;
    background: #cad1ff;
  }

  /deep/#certify .swiper-pagination-bullets .swiper-pagination-bullet-active {
    background: #4a61f1;
  }
}
</style>
