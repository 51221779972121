<!-- 商务合作 -->
<template>
  <div class="business">
    <div class="header">
      <img style="width: 100%;" src="~@/assets/images/business.png" alt="" />
    </div>
    <div class="w content">
      <span class="info">信息填写</span>
      <ul>
        <li>
          <span class="main-tit">公司名称（品牌）：</span
          ><input v-model="params.name" class="promise-ipt" type="text" placeholder="公司名称" />
        </li>
        <li>
          <span class="main-tit">联系人：</span
          ><input v-model="params.contactName" class="promise-ipt" type="text" placeholder="请输入" />
        </li>
        <li>
          <span class="main-tit">备注：</span
          ><input v-model="params.note" class="promise-ipt" type="text" placeholder="请输入" />
        </li>
        <li>
          <span class="main-tit">联系方式：</span
          ><input v-model="params.phone" class="promise-ipt" type="text" placeholder="请输入手机号" />
        </li>
        <li>
          <div class="oneLine">
            <span class="main-tit">意向订购服务时间：</span
            ><input
              type="radio"
              style="font-size: 18rem;color: #ccc;"
              name="aa"
              v-model="params.intendedOrderTime"
              value="1"
            /><span class="radio-txt">免费使用</span>
            <input
              type="radio"
              style="font-size: 18rem;color: #ccc;"
              name="aa"
              v-model="params.intendedOrderTime"
              value="2"
            /><span class="radio-txt">一个月</span>
            <input
              type="radio"
              style="font-size: 18rem;color: #ccc;"
              name="aa"
              v-model="params.intendedOrderTime"
              value="3"
            /><span class="radio-txt">三个月</span>
          </div>
        </li>
        <li>
          <div
            class="form-btn"
            @click="
              () => {
                commitFun();
              }"
          >
            提 交
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { Config } from "../utils/index.js";

export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      params: {
        name: "",
        intendedOrderTime: "1",
        note: "",
        contactName: "",
        phone: ""
      }
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    commitFun() {
      // 提交信息
      let params = { ...this.params };
      if (!params.name) {
        return alert("请填写公司名称！");
      }
      if (!params.intendedOrderTime) {
        return alert("请选择服务时间！");
      }
      if (!params.note) {
        return alert("请填写职位！");
      }
      if (!params.contactName) {
        return alert("请填写联系人！");
      }
      if (!params.phone) {
        return alert("请填写联系方式！");
      }
      this.$axios
        .post(Config.api.url + "/cms/cmsCustomer/insertTryCustomer", params)
        .then(function(res) {
          console.log(res.data.code);
          if (res.data.code == 200) {
            alert("提交成功，请耐心等待我们的联系！");
          } else {
            alert(res.data.message);
          }
        })
        .catch(function(err) {
          console.log(err);
        });
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {}
};
</script>

<style lang="less" scoped>
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60rem auto;
  padding: 99rem 0 60rem;
  // height: 720rem;
  background: #ffffff;
  box-shadow: 0rem 6rem 15rem rgba(20, 71, 199, 0.24);
  border-radius: 30rem;
}
.info {
  font-size: 40rem;
  margin-bottom: 59rem;
}
.promise-ipt {
  background: #fff;
  border: 1rem solid #4a61f1;
  border-radius: 10rem;
  width: 529rem;
  height: 46rem;
  padding: 0 30rem;
  font-size: 18rem;
  color: #333;
  outline: none;
}
.main-tit {
  display: inline-block;
  text-align: right;
  width: 162rem;
  font-size: 18rem;
  color: #333333;
  margin-right: 23rem;
}
.oneLine {
  margin-top: 20rem;
  display: flex;
  align-items: center;
  input {
    cursor: pointer;
  }
  .radio-txt {
    font-size: 18rem;
    color: #666;
    margin-right: 32rem;
    vertical-align: middle;
  }
}
.form-btn {
  text-align: center;
  width: 529rem;
  height: 70rem;
  line-height: 70rem;
  margin: 60rem 185rem 0;
  color: #fff;
  border-radius: 14rem;
  background: #4a61f1;
  box-shadow: 0rem 6rem 15rem rgba(20, 71, 199, 0.24);
  font-size: 26rem;
  cursor: pointer;
}
li {
  margin-bottom: 30rem;
}
</style>
