<template>
    <div class="base-container">
        <div class="base-tabs">
            <div v-for="(item, index) in tabItemList" :key="index" @click="() => { chooseTab = index }" :class="{'chooseTab': chooseTab == index}">{{item}}</div>
        </div>
        <div class="common-title">
            <div v-if="chooseTab == 0">三圆简介—杭州总部</div>
            <div v-if="chooseTab == 1">三圆简介—辽宁基地</div>
            <div v-if="chooseTab == 2">三圆简介—四川基地</div>
            <div v-if="chooseTab == 3">三圆简介—安徽基地</div>
            <div v-if="chooseTab == 4">三圆简介—山东基地</div>
            <div>About Us</div>
        </div>
        <div v-if="chooseTab == 0" class="area-item">
            <div>杭州三圆信息技术有限公司成立于2019年09，位于浙江省杭州市滨江区长河街道长河路。三圆成立之初便致力于客服外包行业标准制定
                可承接售前、售后、临时等多类型客服项目，多年来专注于为电商企业提供客服外包、客服培训、管理外包、管理咨询及软件系统等一
                系列服务的电商客服整体解决方案。自2014年沈阳基地荣获淘宝天猫官方客服外包类目金牌淘拍档以来，三圆以优质的服务和良好的口
                碑，至今已蝉联多次阿里巴巴官方颁发的“金牌淘拍档”。
            </div>
            <div>
                <img src="@/assets/images/hangzhou(3).png">
                <img src="@/assets/images/hangzhou(2).png">
            </div>
        </div>
        <div v-if="chooseTab == 0" class="area-item">
            <div>
                截至2019年，三圆旗下客服基地已达十余个，遍及杭州、沈阳、安徽、山东、四川、等地，拥有专业客服3000余人。并与华为科技、屈臣氏、贝亲、三福时尚女装等众多知名品牌电商和数千家淘宝天猫卖家建立长期合作关系，成为电商客服外包的优选服务商。
            </div>
            <div>
                <img src="@/assets/images/hangzhou(1).png">
                <img src="@/assets/images/hangzhou(4).png">
            </div>
        </div>
        <div v-if="chooseTab == 1" class="area-item">
            <div>
               成立于2014年，拥有办公、培训、大促活动机房等场地、人员、设施完善的客服基地约500平方米。基地配备员工近200人，管理团队20人。目前主要为爱哆哆喜饼、探路者、Lily女装、g-star男装、AO外史密斯、乔丹体育等多个知名品牌提供服务。自成立已承接500人以上双十一项目3个，300人以上618大促活动4个，为马克华菲、热风服饰、美的电器、爱尚鲜花、绫致时装等多个国际知名品牌提供大促服务，期间销售额屡创新高。
            </div>
            <div>
                <img src="@/assets/images/shenyang(1).png">
                <img src="@/assets/images/shenyang(2).png">
            </div>
        </div>
        <div v-if="chooseTab == 2" class="area-item">
            <div>
                三圆四川基地成立于2014年，拥有客服人员500多名，管理人员60余名，并且基地长期与专职院校合作，2017年双十一当天临时客服高达2000人。
                四川基地集淘宝、京东认证服务商一体，长期为企业、个人提供客服外包及其他多项电商服务，目前已与几百个商家建立了长期合作关系。
                基地在2016年，2017年，2018年连续三年获得淘宝“淘拍档银牌服务商”称号，并且在2018年与阿里巴巴签订了战略合作伙伴协议，成为西南地区最有影响力得客服外包服务商。
            </div>
            <div>
                <img src="@/assets/images/sichuan(1).png">
                <img src="@/assets/images/sichuan(2).png">
            </div>
        </div>
        <div v-if="chooseTab == 3" class="area-item">
            <div>
                三圆安徽基地成立于2011年，专注于电商第三方服务外包市场的发展，拥有强大的团队支持。提供包括客服外包在内多项电商服务，拥有电商客户1000余家，是网店在线客服基地中最具有有效实力的专业服务基地。拥有阿里巴巴“银牌淘搭档”称号，是与阿里巴巴签订战略合作协议的正式合作伙伴。基地拥有规范的业务流程，有效的培训团队，健全的质检体系，畅通的投诉渠道，致力于打造高素质、高水平的金牌电商团队；一直在为成为全国金牌网络服务商和客服最满意合作伙伴不懈努力。
            </div>
            <div>
                <img src="@/assets/images/anhui(1).png">
                <img src="@/assets/images/anhui(2).png">
            </div>
        </div>
        <div v-if="chooseTab == 4" class="area-item">
            <div>
                三圆山东基地成立于2014年，2017年入驻阿里巴巴服务市场，并成为服务市场认可的服务商。基地为天猫、淘宝、京东、拼多多、蘑菇街等各大网络平台商的电商提供客服外包服务。以“专注店铺，用心服务”为核心价值，希望通过我们的专业技能和不懈努力，为店铺的客服提供售前售后提供专业的指导。
                基地一直秉承以“买家需求，店主要求”为核心，在专注本地市场开拓的同时，也为全国其他地区各类品牌店铺、个人店铺提供优质、用心、专业的服务，并赢得了业内众多店主的信赖和好评。
                <br/>
                <br/>
                基地不仅仅提供专业客服外包服务，同时还有完善的售前售后监管和维护体系，实时协助店铺发现并解决运营中的问题和困难。我们相信，通过我们不断的努力和追求，一定能实现与各类店铺的管理共赢。
            </div>
            <div>
                <img src="@/assets/images/shandong(1).png">
                <img src="@/assets/images/shandong(2).png">
            </div>
        </div>
        
    </div>
</template>
<script>
export default {
    data() {
        return {
            chooseTab: 0,
            tabItemList: ['杭州总部', '辽宁基地', '四川基地', '安徽基地', '山东基地']
        }
    }
}
</script>
<style lang="less" scoped>
.base-container {
    width: 100%;
    padding: 0 320rem;
    .area-item {
        padding-bottom: 34rem;
        div {
            &:nth-of-type(1) {
                font-size: 18rem;
                color: #666;
                line-height: 29rem;
                padding: 0 24rem;
            }
            &:nth-of-type(2) {
                display: flex;
                justify-content: space-between;
                img {
                    width: 625rem;
                }
            }
        }
    }
    .base-tabs {
        display: flex;
        height: 58rem;
        align-items: flex-end;
        div {
            color: #333;
            font-size: 18rem;
            cursor: pointer;
            margin-right: 95rem;
        }
        .chooseTab {
            color: #4A61F1;
        }
    }
}
</style>