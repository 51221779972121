<template>
    <div class="contact-container">
        <div class="map-container">
            <baidu-map id="allmap" @ready="mapReady"></baidu-map>
        </div>
        <div class="contact-form-container">
            <div class="form-title">联系我们</div>
            <div class="form-tip">无论您是咨询业务还是对我们提出建议，都可以随时联系我们 三圆竭诚为您服务</div>
            <div class="form-info">
                <div><span class="info-item"></span><span>电话：18255036741</span></div>
                <div><span class="info-item"></span><span>商务合作：18255036741</span></div>
                <div><span class="info-item"></span><span>邮箱：3225869897@qq.com</span></div>
                <div><span class="info-item"></span><span>客户经理：19855530656</span></div>
                <div><span class="info-item"></span><span>投诉/招商电话：400-000-3061</span></div>
                <!-- <div><span class="info-item"></span><span>电话：18255036741</span></div> -->
            </div>
            <div class="input-container">
                <input class="form-input" type="text" placeholder="公司名称（品牌）" v-model="params.name">
                <input class="form-input" type="text" placeholder="备注" v-model="params.note">
            </div>
            <div class="input-container">
                <input class="form-input" type="text" placeholder="联系人" v-model="params.contactName">
                <input class="form-input" type="text" placeholder="联系方式" v-model="params.phone">
            </div>
            <div class="oneLine">
                <span>意向订购服务时间：</span>
                <input type="radio" name="radio" v-model="params.intendedOrderTime" value="1"/><span class="radio-txt">免费使用</span>
                <input type="radio" name="radio" v-model="params.intendedOrderTime" value="2"/><span class="radio-txt">一个月</span>
                <input type="radio" name="radio" v-model="params.intendedOrderTime" value="3"/><span class="radio-txt">三个月</span>
            </div>
            <div class="form-btn" @click="() => { commitFun() }"> 提 交 </div>
        </div>
    </div>
</template>
<script>
import { Config } from "../../utils/index.js"
export default {
    data () {
        return {
            point: "",
            params: {
                name: '',
                intendedOrderTime: '1',
                note: '',
                contactName: '',
                phone: ''
            }
        }
    },
    methods: {
        mapReady({ BMap, map }) {
            // 选择一个经纬度作为中心点
            this.point = new BMap.Point(120.202065,30.189211);
            let marker1 = new BMap.Marker(this.point);
            map.addOverlay(marker1); // 添加标记点
            map.enableScrollWheelZoom(true)
            map.centerAndZoom(this.point, 12);
        },
        commitFun() { // 提交信息
            let params = {...this.params}
            if (!params.name) { return alert('请填写公司名称！') }
            if (!params.intendedOrderTime) { return alert('请选择服务时间！') }
            if (!params.note) { return alert('请填写备注！') }
            if (!params.contactName) { return alert('请填写联系人！') }
            if (!params.phone) { return alert('请填写联系方式！') }
            this.$axios.post(Config.api.url + '/cms/cmsCustomer/insertTryCustomer', params).then(function (res) {
                console.log(res.data.code);
                if (res.data.code == 200) {
                    alert('提交成功，请耐心等待我们的联系！')
                } else {
                    alert(res.data.message)
                }
            }).catch(function (err) {
                console.log(err);
            });
        }
    }
}
</script>
<style lang="less" scoped>

.contact-container {
    padding: 0 360rem 100rem;
    .map-container {
        width: 100%;
        height: 645rem;
        margin: 39rem 0 71rem;
        border-radius: 30rem;
        box-shadow: 0px 6px 15px rgba(20, 71, 199, 0.24);
        overflow: hidden;
        #allmap{
            height: 100%;
            width: 100%;
            margin: 0 auto;
            border-radius: 30rem;
        }
    }
    .oneLine {
        margin-top: 20rem;
        display: flex;
        align-items: center;
        input {
            cursor: pointer;
        }
        .radio-txt {
            font-size: 12rem;
            color: #666;
            margin-right: 32rem;
            vertical-align: middle;
        }
    }
    .form-btn {
        text-align: center;
        width: 529rem;
        height: 70rem;
        line-height: 70rem;
        margin: 60rem auto;
        color: #fff;
        border-radius: 14rem;
        background: #4A61F1;
        box-shadow: 0px 6px 15px rgba(20, 71, 199, 0.24);
        font-size: 26rem;
        cursor: pointer;
    }
    .input-container {
        display: flex;
        justify-content: space-between;
        margin-top: 20rem;
        .form-input {
            background: #fff;
            border: 1px solid #4A61F1;
            border-radius: 10rem;
            width: 400rem;
            height: 46rem;
            padding: 0 30rem;
            font-size: 18rem;
            color: #333;
            outline: none;
            font-family: "微软雅黑", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
        }
    }
    .contact-form-container {
        width: 100%;
        background: #fff;
        border-radius: 30rem;
        box-shadow: 0px 6px 15px rgba(20, 71, 199, 0.24);
        padding: 98rem 120rem 60rem;
        .form-info {
            div {
                width: 49%;
                display: inline-flex;
                align-items: center;
                line-height: 40rem;
                span {
                    color: #333;
                    font-size: 20rem;
                }
                .info-item {
                    display: inline-block;
                    height: 20rem;
                    width: 20rem;
                    background: #4A61F1;
                    border: solid #C4CBFF 4rem;
                    border-radius: 50%;
                    margin-right: 20rem;
                }
            }
            
        }
        .form-title {
            color: #000;
            font-size: 40rem;
            text-align: center;
            line-height: 1;
        }
        .form-tip {
            color: #333;
            font-size: 26rem;
            line-height: 82rem;
        }
    }
}
</style>