<template>
  <div class="about-container">
    <img class="about-img" src="@/assets/images/about_banner.png" alt="">
    <div class="about-tabs-container">
      <div v-for="(item) in routeList" :key="item.routeUrl" @click="() => { chooseTab(item.routeUrl) }" :class="item.routeUrl == chooseRoute ? 'tab-active-item' : ''">{{item.name}}</div>
     
    </div>
    <router-view/>
  </div>
</template>
<script>
export default {
  data() {
    return {
      chooseRoute: '/about/base',
      routeList: [
        {name: '三圆简介', routeUrl: '/about/base'},
        {name: '组织架构', routeUrl: '/about/manage'},
        {name: '发展历程', routeUrl: '/about/development'},
        {name: '企业文化', routeUrl: '/about/company'},
        {name: '联系我们', routeUrl: '/about/contact'},
      ]
    }
  },
  created() {
    let pathname = window.location.pathname
    this.chooseRoute = pathname
  },
  methods: {
    chooseTab(url) {
      this.chooseRoute = url
      this.$router.push(url)
    }
  }
}
</script>
<style lang="less" scoped>
.about-container {
  .about-img {
    width: 100%;
  }
  .about-tabs-container {
    width: 100%;
    padding: 0 360rem;
    height: 102rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    div {
      height: 102rem;
      line-height: 100rem;
      cursor: pointer;
      font-size: 24rem;
      color: #333;
    }
    .tab-active-item {
      border-bottom: 4rem solid #4A61F1;
      color: #4A61F1;
    }
  }
}
</style>
