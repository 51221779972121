<template>
    <div class="cases-container">
        <div class="service-of-king">
            “当日的<span class="red-text">12.9</span>亿客服天团是谁？”
        </div>
        <div class="img-container">
            <img src="@/assets/images/cases(1).png" alt="">
        </div>
        <div class="common-blue-title">三圆经典案例</div>
        <div class="img-container">
            <img src="@/assets/images/cases(3).png" alt="">
        </div>
        <div class="img-container">
            <img src="@/assets/images/cases(5).png" alt="">
        </div>
        <div class="img-container">
            <img src="@/assets/images/cases(4).png" alt="">
        </div>
        <div class="black-text">“三圆客服是您背后的最坚实的支柱力量”</div>
        <div class="common-blue-title">高端服务品质的保障</div>
        <div class="service-quality-container">
            <div class="text-div">
                <div class="text-div-title">三圆提供精准数据分析</div>
                <div class="text-div-item">
                    <img src="@/assets/images/cases-1-(1).png" alt="">
                    <span>人群分析</span>
                </div>
                <div class="text-div-item">
                    <img src="@/assets/images/cases-1-(2).png" alt="">
                    <span>单价分析</span>
                </div>
                <div class="text-div-item">
                    <img src="@/assets/images/cases-1-(3).png" alt="">
                    <span>爆款分析</span>
                </div>
                <div class="text-div-item">
                    <img src="@/assets/images/cases-1-(4).png" alt="">
                    <span>咨询量分析</span>
                </div>
                <div class="text-div-item">
                    <img src="@/assets/images/cases-1-(5).png" alt="">
                    <span>类目分析</span>
                </div>
                <div style="margin: 32rem 0 0 52rem;">
                    <span style="font-weight: 700">· · ·</span>
                </div>
            </div>
            <img src="@/assets/images/cases(6).png" alt="">
        </div>
        <div class="service-quality-container">
            <img src="@/assets/images/cases(7).png" alt="">
            <div class="text-div">
                <div class="text-div-title">三圆对产品及客户分析</div>
                <div class="text-div-item">
                    <img src="@/assets/images/cases-2-(1).png" alt="">
                    <span>排版分析</span>
                </div>
                <div class="text-div-item">
                    <img src="@/assets/images/cases-2-(2).png" alt="">
                    <span>人力预估与分配</span>
                </div>
                <div class="text-div-item">
                    <img src="@/assets/images/cases-2-(3).png" alt="">
                    <span>售前售后人物分配</span>
                </div>
                <div class="text-div-item">
                    <img src="@/assets/images/cases-2-(4).png" alt="">
                    <span>流量时段分析</span>
                </div>
                <div class="text-div-item">
                    <img src="@/assets/images/cases-2-(5).png" alt="">
                    <span>爆量时段分析</span>
                </div>
            </div>
        </div>
        <div class="service-quality-container">
            <div class="text-div">
                <div class="text-div-title">三圆对品牌运营指导分析</div>
                <div class="text-div-item">
                    <img src="@/assets/images/cases-3-(1).png" alt="">
                    <span>话术引导</span>
                </div>
                <div class="text-div-item">
                    <img src="@/assets/images/cases-3-(2).png" alt="">
                    <span>快捷短语制定</span>
                </div>
                <div class="text-div-item">
                    <img src="@/assets/images/cases-3-(3).png" alt="">
                    <span>转化率分析</span>
                </div>
                <div class="text-div-item">
                    <img src="@/assets/images/cases-3-(4).png" alt="">
                    <span>质检分析</span>
                </div>
                <div class="text-div-item">
                    <img src="@/assets/images/cases-3-(5).png" alt="">
                    <span>品牌问题分析</span>
                </div>
            </div>
            <img src="@/assets/images/cases(8).png" alt="">
        </div>
        <div class="common-blue-title" style="margin-top: 99rem">联系我们</div>
        <div class="common-blue-title" style="margin-top: 57rem;font-size: 30rem">杭州三圆客服外包</div>
        <div class="contact-text"> 三圆以“提供专业的电商客服服务，助力客户成功”为使命，公司定位于为电商企业提供电商客服服务整体解决方案，致力于电商客服服务的标准制定者、领导者。</div>
        <div class="form-container">
            <div class="oneLine">
                <span class="form-item-title">公司名称（品牌）：</span>
                <input class="form-input" type="text" v-model="params.name" placeholder="公司名称"/>
            </div>
            <div class="oneLine">
                <span class="form-item-title">联系人：</span>
                <input class="form-input" type="text" v-model="params.contactName" placeholder="联系人"/>
            </div>
            <div class="oneLine">
                <span class="form-item-title">备注：</span>
                <input class="form-input" type="text" v-model="params.note" placeholder="备注"/>
            </div>
            <div class="oneLine">
                <span class="form-item-title">联系方式：</span>
                <input class="form-input" type="text" v-model="params.phone" placeholder="联系方式"/>
            </div>
             <div class="oneLine">
                <span class="form-item-title">意向订购服务时间：</span>
                <input type="radio" name="radio" v-model="params.intendedOrderTime" value="1"/><span class="radio-txt">免费使用</span>
                <input type="radio" name="radio" v-model="params.intendedOrderTime" value="2"/><span class="radio-txt">一个月</span>
                <input type="radio" name="radio" v-model="params.intendedOrderTime" value="3"/><span class="radio-txt">三个月</span>
            </div>
             <div class="oneLine">
                <span class="form-item-title"></span>
               <div class="form-btn" @click="() => { commitFun() }"> 提 交 </div>
            </div>
            
        </div>
        
    </div>
</template>
<script>
import { Config } from "../utils/index.js"
export default {
    data () {
        return {
            point: "",
            params: {
                name: '',
                intendedOrderTime: '1',
                note: '',
                contactName: '',
                phone: ''
            }
        }
    },
    methods: {
        commitFun() { // 提交信息
            let params = {...this.params}
            if (!params.name) { return alert('请填写公司名称！') }
            if (!params.intendedOrderTime) { return alert('请选择服务时间！') }
            if (!params.note) { return alert('请填写备注！') }
            if (!params.contactName) { return alert('请填写联系人！') }
            if (!params.phone) { return alert('请填写联系方式！') }
            this.$axios.post(Config.api.url + '/cms/cmsCustomer/insertTryCustomer', params).then(function (res) {
                console.log(res.data.code);
                if (res.data.code == 200) {
                    alert('提交成功，请耐心等待我们的联系！')
                } else {
                    alert(res.data.message)
                }
            }).catch(function (err) {
                console.log(err);
            });
        }
    }
}
</script>
<style lang="less" scoped>
.cases-container {
    padding: 1px 360rem 1px;
    background: #F8FAFF;
    .form-container {
        padding-left: 220rem;
    }
     .oneLine {
        margin-top: 20rem;
        display: flex;
        align-items: center;
        .form-item-title {
            display: inline-block;
            width: 200rem;
            text-align: right;
        }
        .form-input {
            background: #fff;
            border: 1px solid #4A61F1;
            border-radius: 10rem;
            width: 400rem;
            height: 46rem;
            padding: 0 30rem;
            font-size: 18rem;
            color: #333;
            outline: none;
            font-family: "微软雅黑", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
        }
        input {
            cursor: pointer;
        }
        .radio-txt {
            font-size: 12rem;
            color: #666;
            margin-right: 32rem;
            vertical-align: middle;
        }
    }
    .form-btn {
        text-align: center;
        width: 470rem;
        height: 70rem;
        line-height: 70rem;
        margin: 60rem 0;
        color: #fff;
        border-radius: 14rem;
        background: #4A61F1;
        box-shadow: 0px 6px 15px rgba(20, 71, 199, 0.24);
        font-size: 26rem;
        cursor: pointer;
    }
    .contact-text {
        text-align: center;
        font-size: 20rem;
        line-height: 36rem;
        color: #333;
        margin-top: 25rem;
    }
    .service-quality-container {
        width: 100%;
        display: flex;
        overflow-x: auto;
        border-radius: 30rem;
        box-shadow: 0px 6px 15px rgba(38, 21, 200, 0.24);
        margin-top: 59rem;
        .text-div {
            flex: 1;
            padding-left: 90rem;
            .text-div-item {
                display: flex;
                align-items: center;
                margin-top: 30rem;
                img {
                    width: 34rem;
                    height: 34rem;
                    margin-right: 18rem;
                }
                span {
                    color: #333;
                    font-size: 24rem;
                    line-height: 1;
                }
            }
            .text-div-title {
                font-size: 32rem;
                color: #4A61F1;
                margin: 62rem 0;
            }
        }
        img {
            width: 745rem;
        }
    }
    .black-text {
        text-align: center;
        font-size: #333;
        font-size: 32rem;
        margin-bottom: 100rem;
    }
    .img-container {
        width: 100%;
        img {
            width: 100%;
        }
    }
    .service-of-king {
        font-size: 66rem;
        margin-top: 50rem;
        text-align: center;
        color: #4A61F1;
        .red-text {
            font-size: 80rem;
            color: #F14A4A;
        }
    }
}
</style>