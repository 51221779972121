<template>
    <div class="home-container">
        <div class="swiperSelf-container">
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide banner-01">
                        <div class="banner-text-container">
                            <div class="banner-title">三圆客服</div>
                            <div class="banner-info">
                                全渠道客服解决方案
                                <br />
                                助力企业构建专业客服对接  进入客服新时代
                            </div>
                            <div class="banner-btn" @click="() => { $router.push('/about/base') }">了解更多</div>
                        </div>
                        <!-- <img @click="() => { $router.push('/about/base') }" class="swiper-img" src="@/assets/images/swiper_01.png" alt=""> -->
                    </div>
                    <div class="swiper-slide banner-02">
                        <div class="banner-text-container">
                            <div class="banner-title">三圆客服</div>
                            <div class="banner-info">
                                赋能线上店铺  提升服务口碑 
                            </div>
                            <div class="banner-btn" @click="() => { $router.push('/about/base') }">了解更多</div>
                        </div>
                        <!-- <img @click="() => { $router.push('/about/development') }" class="swiper-img" src="@/assets/images/swiper_02.png" alt=""> -->
                    </div>
                </div>
                <div class="swiper-pagination"></div>
            </div>
        </div>
        <div class="service-data">
            <div class="service-data-top">
                <div class="service-data-item">
                    <div>5,000+</div>
                    <div>服务器集群</div>
                </div>
                <div class="service-data-item">
                    <div>1,000+</div>
                    <div>数据库集群</div>
                </div>
                <div class="service-data-item">
                    <div>3,000+</div>
                    <div>全球CDN加速节点</div>
                </div>
                <div class="service-data-item">
                    <div>600G</div>
                    <div>网络攻击防护规模</div>
                </div>
               
            </div>
            <div class="service-data-bottom">
                <div>为全球 <span>3,284,396</span>商户</div>
                <div>提供7*16小时优质服务</div>
            </div>
        </div>
        <div class="home-content">
            <div class="common-title" id="homeM01">
                <div>旗下企业</div>
                <div>Company</div>
            </div>
            <div class="adress-container">
                <div class="adress-item">
                    <img src="@/assets/images/adress-(1).png" alt="">
                    <div>杭州总部</div>
                </div>
                <div class="adress-item">
                    <img src="@/assets/images/adress-(2).png" alt="">
                    <div>沈阳基地</div>
                </div>
                <div class="adress-item">
                    <img src="@/assets/images/adress-(3).png" alt="">
                    <div>四川基地</div>
                </div>
                <div class="adress-item">
                    <img src="@/assets/images/adress-(4).png" alt="">
                    <div>安徽基地</div>
                </div>
                <div class="adress-item">
                    <img src="@/assets/images/adress-(5).png" alt="">
                    <div>山东基地</div>
                </div>
            </div>
            <div class="common-title" id="homeM02">
                <div>客户成功案例</div>
                <div>Cases</div>
            </div>
            <div class="case-container">
                <div class="case-item">
                    <img src="@/assets/images/case(3).png" alt="">
                    <img src="@/assets/images/case(1).png" alt="">
                    <div>屈臣氏</div>
                    <div>
                        屈臣氏氏天猫旗舰店在17年3月由本公司进行承接 ，在接
                        线前，店铺的平均每月销售额是在两千万元左右。接号
                        后，平均每月的销售额在五千万左右。同比接号前当月总
                        咨询量增加了100多万人数。平响从原先的53S降低到
                        19.46S答问比，回复率以及转化率都有所提高。
                    </div>
                </div>
                <div class="case-item">
                    <img src="@/assets/images/case(4).png" alt="">
                    <img src="@/assets/images/case(2).png" alt="">
                    <div>玉泽</div>
                    <div>
                        玉泽官方旗舰店在18年7月由本公司进行承接，在接线
                        前，店铺平均每月销售额在350万左右。接号后同比去年
                        当月销售额增加了600万左右。同比接号前，当月总咨询
                        量增加了35%左右，时效从原先的34S降低到27S左右，
                        答问比进行了提高。<br/><br/>
                    </div>
                </div>
            </div>
            <div class="common-title" id="homeM03">
                <div>众多企业的信赖</div>
                <div class="title-btn" @click="() => { $router.push('/business') }">立即订购服务</div>
            </div>
            <div class="company-container">
                <div>
                    <img src="@/assets/images/company(1).png" alt="">
                    <img src="@/assets/images/company(2).png" alt="">
                    <img src="@/assets/images/company(3).png" alt="">
                    <img src="@/assets/images/company(4).png" alt="">
                    <img src="@/assets/images/company(5).png" alt="">
                    <img src="@/assets/images/company(6).png" alt="">
                    <img src="@/assets/images/company(7).png" alt="">
                </div>
                <div>
                    <img src="@/assets/images/company(8).png" alt="">
                    <img src="@/assets/images/company(9).png" alt="">
                    <img src="@/assets/images/company(10).png" alt="">
                    <img src="@/assets/images/company(11).png" alt="">
                    <img src="@/assets/images/company(12).png" alt="">
                    <img src="@/assets/images/company(13).png" alt="">
                </div>
            </div>
						
        </div>
			
    </div>
</template>
<script>
import Swiper from "swiper";
export default {
    components: {
    },
    mounted() {
        let swiper = new Swiper(".swiper-container", {
            slidesPerView: 1,
            centeredSlides: true,
            autoplay: true,
            pagination: {
            el: ".swiper-pagination",
                clickable: true
            }
        });
        swiper.init()
    }
}
</script>
<style lang="less" scoped>
.home-container {
    background: #F8FAFF;
    .home-content {
        width: 100%;
        padding: 0 360rem 60rem;

        .company-container {
            width: 100%;
            height: 342rem;
            border-radius: 30rem;
            box-shadow: 0px 6px 15px rgba(20, 71, 199, 0.24);
            background: #fff;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            div {
                display: flex;
                justify-content: space-evenly;
                img {
                    width: 145rem;
                    height: 145rem;
                }
                &:nth-of-type(1) {
                    padding: 0 40rem;
                }
                &:nth-of-type(2) {
                    padding: 0 120rem;
                }
            }
        }
        .case-container {
            display: flex;
            justify-content: space-between;
            .case-item {
                width: 580rem;
                height: 740rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                background: #fff;
                border-radius: 30rem;
                box-shadow: 0px 6px 15px rgba(20, 71, 199, 0.24);
                img {
                    &:nth-of-type(1) {
                        height: 92rem;
                        width: 128rem;
                    }
                    &:nth-of-type(2) {
                        height: 300rem;
                        width: 500rem;
                    }
                }
                div {
                    padding: 0 40rem;
                     &:nth-of-type(1) {
                        font-size: 30rem;
                        color: #000;
                    }
                    &:nth-of-type(2) {
                        font-size: 18rem;
                        line-height: 28rem;
                        color: #666;
                    }
                }
            }
        }
        .adress-container {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            .adress-item {
                text-align: center;
                img {
                    height: 250rem;
                    width: 250rem;
                }
                div {
                    color: #000;
                    font-size: 30rem;
                }
            }
        }
    }
    .service-data {
        width: 100%;
        background: #fff;
        height: 592rem;
        padding: 0 360rem;
        .service-data-bottom {
            height: 208rem;
            width: 100%;
            border-radius: 30rem;
            background: #F8FAFF;
            box-shadow: 0px 6px 15px rgba(20, 71, 199, 0.24);
            padding: 34rem 0;
            div {
                letter-spacing: 30rem;
                font-size: 38rem;
                text-align: center;
                color: #333;
                span {
                    font-size: 60rem;
                    color: #4A61F1;
                }
            }
        }
        .service-data-top {
            display: flex;
            justify-content: space-between;
            height: 280rem;
            padding: 95rem 0;
            .service-data-item {
                div {
                    line-height: 1;
                    text-align: center;
                    &:nth-of-type(1) {
                        color: #333;
                        font-size: 38rem;
                    }
                    &:nth-of-type(2) {
                        color: #666;
                        font-size: 20rem;
                        margin-top: 28rem;
                    }
                }
            }
        }
    }
    .swiperSelf-container {
        height: 600rem;
        position: relative;
        width: 100%;
        .swiper-pagination {
            width: 100%;
            height: 40rem;
            bottom: 0;
            /deep/.swiper-pagination-bullet {
                margin-right: 10rem;
                width: 30rem;
                height: 10rem;
                border-radius: 5rem;
            }
            /deep/.swiper-pagination-bullet-active {
                background: #4A61F1;
            }
        }
        .banner-01 {
            width: 100%;
            height: 600rem;
            background: url('../assets/images/home_banner01.png') 870rem 0 no-repeat;
            background-size: auto 600rem;
        }
        .banner-02 {
            width: 100%;
            height: 600rem;
            background: url('../assets/images/home_banner02.png') 870rem 0 no-repeat;
            background-size: auto 600rem;
        }
        .banner-text-container {
            margin-left: 358rem;
            padding-top: 1px;
            .banner-title {
                font-size: 60rem;
                line-height: 1;
                margin-top: 98rem;
                color: #000;
            }
            .banner-info {
                font-size: 34rem;
                line-height: 60rem;
                margin: 60rem 0 50rem;
                color: #333;
            }
            .banner-btn {
                width: 230rem;
                height: 70rem;
                text-align: center;
                line-height: 70rem;
                color: #fff;
                border-radius: 14rem;
                background: #4A61F1;
                font-size: 26rem;
                box-shadow: 0px 6px 15px rgba(20, 71, 199, 0.24);
                cursor: pointer;
            }
        }
        // .swiper-slide {
        //     width: 100%;
        // }
        // .swiper-container {
        //     width: 100%;
        // }
        // .swiper-wrapper {
        //     width: 100%;
        // }
        .swiper-img {
            height: 600rem;
            // width: 100%;
            cursor: pointer;
        }
    }
}
</style>